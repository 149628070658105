<template>
  <div>
    <div class="banner_info" :style="`background: url(images/2_02.jpg) center no-repeat;background-size: cover;`">
      <img src="images/2_03.png">
    </div>
    <div class="infopage">
      <div class="container clearfix">
        <div class="left">
          <div class="box" >
            <dl>
              <dt>培训学习</dt>
              <dd>Training and learning</dd>
            </dl>
            <ul>
              <li
                  :class="{on:item.id == cId}"
                  v-for="(item,index) in leftData"
                  :key="item.id"
                  @click="itemClick(item,index)"
              ><a href="javascript:;">{{item.name}}</a></li>
            </ul>
          </div>

        </div>
        <div class="right">
          <div class="box" v-for="(item,index) in columnData" :key="item.id">
            <div class="title flex">
              <div class="flex_item"><b>{{item.name}}</b></div>

              <a href="javascript:;" @click="toMore(item.id)" class="more">更多</a>
            </div>
            <div class="bd">
              <ul class="newslist">
                <li v-for="val in item.articleList.slice(0,10)" :key="val.id">
<!--                  <div class="flex_item" @click="openNewPage(val)"><a href="javascript:;">{{val.title}}</a></div>-->
                  <div class="flex_item" @click="toDetail(val.id,item.name)"><a href="javascript:;">{{val.title}}</a></div>
                  <!--              插入申请按钮-->
                  <a class="apply" href="javascript:;" v-if="['培训通知','培训计划'].includes(item.name)" @click="apply(val)">
                    立即申请
                  </a>
                  <span>{{val.publishTime}}</span>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions,mapState} from 'vuex';
export default {
  name: "train",
  data(){
    return {
      pid:0,
      id:0,
      index:0,
      cId:0,
      columnData:[],
      leftData:[]
    }
  },
  mounted() {
    let route =  this.$route.query
    this.pid = route?.pid
    this.id = route?.id
    this.index = route?.index
    console.log(this.id)
    this.$api.getChildColumn({columnId:this.pid}).then(res=>{
      this.leftData = res.data.data
      console.log(this.leftData)
      this.cId = this.leftData[this.index].id
      this.findColumnData()
    })
  },
  methods:{
    ...mapActions(['columnAction']),
    itemClick(item,i){
      this.cId = item.id
      this.index  = i;
      this.findColumnData()
    },
    async findColumnData(){
      let res = await this.$api.getChildColumn({columnId:this.cId});
      this.columnData = res.data.data;
      console.log(this.columnData,'--');
    },
    openNewPage(item){
      console.log(item);
      let { href } = this.$router.resolve({ path: '/detail',query:{pid:this.pid,id:this.cId,tabIndex:this.tabIndex,level:1,index:this.index,name:'培训学习',articleId:item.id} })
      window.open(href, '_blank')
    },
    toDetail(id,name){
      this.$router.push({path:'/view',query:{articleId:id,name:name}})
    },
    toMore(cid){
      this.$router.push({path:'/list',query:{pid:cid}})
    },
    apply(val){
      this.$router.push("/apply?id=393&pid=374&index=1")
    }
  },
  computed:{
    ...mapState(["cacheColumn",'tabIndex']),
  }
}
</script>

<style scoped>
.apply{
  display: inline-block;
  background: #09459b;
  font-size: 14px;
  border-radius: 20px;
  /*padding: 3px 10px;*/
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  margin: 0 10px;
  color: #FFFFFF;
}
</style>
